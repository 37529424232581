import {
  AdyenCheckout,
  Dropin,
  DropinConfiguration,
  CoreConfiguration,
  PaymentMethodsResponse,
  PaymentData,
  InstantPaymentTypes,
} from "@adyen/adyen-web/auto";
import { renderToString } from "jsx-async-runtime";
import {
  ADYEN_DROPIN_CONTAINER,
  ERROR,
  ERROR_OCCURRED_SUBMITTING_PAYMENT,
  FORM_ERROR_CONTAINER,
  FORM_LOADING_SPINNER,
  INCLUDE,
  LIVE,
  LOADING,
  OMIT,
  PAYMENT_ERRORED,
  PAYMENT_METHOD_SUBTYPE,
  PAYMENT_SUCCEEDED,
  GOOGLEPAY,
  PROBLEM_SUBMITTING_PAYMENT,
  READY,
  REFUSED,
  SUCCESS,
  TEST,
  UNKNOWN_ERROR_OCCURRED,
  INSTANT_PAYMENT_TYPES,
} from "../constants";
import HttpRequest, { ApiResultType } from "../utils/HttpRequest";
import { FormError } from "./form-error";
import { buildFatalError } from "../model/fatalError";
import PaymentSettings from "../model/payment/PaymentSettings";
import { PaymentMethodType } from "../model/common/PaymentMethodType";
import AdyenProviderSettings from "../model/common/providers/adyen/AdyenProviderSettings";

export type AdyenDropinContainerConfiguration = {
  countryCode: string;
  clientKey: string;
  environment: typeof LIVE | typeof TEST;
  sessionId: string;
  brand: string;
  culture: string;
  apiBaseUrl: string;
  hostContainerId: string;
} & PaymentSettings;

export class AdyenDropinContainer extends HTMLElement {
  private _configuration: AdyenDropinContainerConfiguration;
  private _dropin: Dropin;

  constructor() {
    super();
  }

  get amount() {
    // Get the amount to show the user, this is for display purposes only
    const originalAmount =
      this.configuration.paymentTimelineSummary.overviewLines[0].amount;
    if (originalAmount && originalAmount.value > 0) {
      return { ...originalAmount, value: originalAmount.value * 100 };
    }
    return originalAmount;
  }

  get configuration() {
    return this._configuration;
  }

  set configuration(value) {
    value.countryCode = value.culture.substring(3);
    this._configuration = value;
  }

  async getPaymentMethods() {
    let credentials = OMIT as RequestCredentials;
    if (this._configuration.environment === TEST) {
      credentials = INCLUDE as RequestCredentials;
    }

    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/getpaymentmethods`;

    const response = await HttpRequest.get({
      url,
      credentials,
      queryParams: {
        sessionId: this.configuration.sessionId,
      },
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response.data;
  }

  coreConfiguration(paymentMethods: PaymentMethodsResponse) {
    let config: CoreConfiguration = {
      clientKey: this.configuration.clientKey,
      environment: this.configuration.environment,
      locale: this.configuration.culture,
      countryCode: this.configuration.countryCode,
      paymentMethodsResponse: paymentMethods,
      onSubmit: this.handleOnSubmit.bind(this),
      onAdditionalDetails: this.handleAdditionalDetails.bind(this),
      amount: this.amount,
    };
    return config;
  }

  mapCardBrandsToGooglePayBrands(brand: string): google.payments.api.CardNetwork {
    switch (brand) {
      case "visa":
        return "VISA";
      case "mc":
        return "MASTERCARD";
      case "amex":
        return "AMEX";
      case "discover":
        return "DISCOVER";
      default:
        return null;
    }
  }

  dropinConfiguration() {
    const googleCardBrands = (this.configuration.paymentMethods.find(x => x.type === PaymentMethodType.Card).providerSettings as AdyenProviderSettings)
      .allowedCards.map(x => this.mapCardBrandsToGooglePayBrands(x.brand))
      .filter(x => !!x);

    let dropinConfiguration: DropinConfiguration = {
      instantPaymentTypes: INSTANT_PAYMENT_TYPES as InstantPaymentTypes[],
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: false,
          billingAddressRequired: false,
          challengeWindowSize: "05", // ['100%', '100%']
        },
        paywithgoogle: {
          allowedCardNetworks: googleCardBrands
        },
        googlepay: {
          allowedCardNetworks: googleCardBrands
        },
      },
      onReady: () => {
        const formSpinner = document.getElementById(FORM_LOADING_SPINNER);
        if (!!formSpinner) {
          document.getElementById(this.configuration.hostContainerId).hidden = false;
          formSpinner.hidden = true;
        }
      }
    };
    return dropinConfiguration;
  }

  handleServerResponse(response, actions): boolean {
    const formErrorContainer = document.getElementById(FORM_ERROR_CONTAINER);
    if (formErrorContainer) formErrorContainer.innerHTML = "";
    if (
      response.result === ApiResultType.ClientError ||
      response.result === ApiResultType.NetworkError
    ) {
      const formError = document.createElement(
        FORM_ERROR_CONTAINER
      ) as FormError;
      formError.error = response.error?.detail ?? UNKNOWN_ERROR_OCCURRED;
      if (response.result === ApiResultType.NetworkError)
        formError.error = PROBLEM_SUBMITTING_PAYMENT;
      formErrorContainer.appendChild(formError);
      formError.scrollIntoView();

      if (response.error.extensions) {
        if (
          response.error.extensions[PAYMENT_METHOD_SUBTYPE] === GOOGLEPAY &&
          actions &&
          response.error.instance !== "/adyenprocessor/completethreeds2"
        ) {
          actions.reject();
        }
      }

      this._dropin.setStatus(READY);

      return false;
    }
    if (response.result === ApiResultType.ServerError) {
      this.raisePaymentErrored(
        buildFatalError(response.error, ERROR_OCCURRED_SUBMITTING_PAYMENT)
      );
      return false;
    }
    return true;
  }

  async handlerBase(state, actions, additionalDetails: boolean) {
    try {
      this._dropin.setStatus(LOADING);
      let response: any;
      if (additionalDetails) {
        response = await this.makeDetailsCall(state.data);
      } else {
        response = await this.makePayment(state.data);
      }
      const shouldProcessResponse = this.handleServerResponse(
        response,
        actions
      );

      if (!shouldProcessResponse) return;

      const { action, order, resultCode, donationToken, authorisationResult } =
        response.data;
      const result = resultCode || authorisationResult;

      actions.resolve({
        resultCode: result,
        action,
        order,
        donationToken,
      });

      if (!action) {
        this.handleResponse(result);
      }
    } catch (error) {
      this._dropin.setStatus(ERROR);
      actions.reject();
    }
  }

  async handleOnSubmit(state, _, actions) {
    await this.handlerBase(state, actions, false);
  }

  async handleAdditionalDetails(state, _, actions) {
    await this.handlerBase(state, actions, true);
  }

  async makePayment(stateData: PaymentData) {
    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/makepayment`;

    let credentials = OMIT as RequestCredentials;
    if (this._configuration.environment == TEST) {
      credentials = INCLUDE as RequestCredentials;
    }

    const response = await HttpRequest.post({
      url,
      credentials,
      body: JSON.stringify({
        payload: JSON.stringify(stateData),
        sessionId: this.configuration.sessionId,
        enableRecurring: false,
      }),
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response;
  }

  async makeDetailsCall(stateData: {
    details: any;
    paymentData?: string;
    sessionData?: string;
  }) {
    const url = `${this.configuration.apiBaseUrl}/adyenprocessor/completethreeds2`;

    const response = await HttpRequest.post({
      url,
      body: JSON.stringify({
        authenticationResult: stateData.details.threeDSResult,
        sessionId: this.configuration.sessionId,
      }),
      brand: this.configuration.brand,
      culture: this.configuration.culture,
    });
    return response;
  }

  raisePaymentSuccess() {
    this._dropin.setStatus(SUCCESS);
    const event = new Event(PAYMENT_SUCCEEDED);
    window.dispatchEvent(event);
  }

  raisePaymentErrored(response) {
    this._dropin.setStatus(ERROR);
    const event = new CustomEvent(PAYMENT_ERRORED, { detail: response });
    window.dispatchEvent(event);
  }

  handleResponse(resultCode) {
    if (resultCode === REFUSED) {
      this.raisePaymentErrored(resultCode);
    } else {
      this.raisePaymentSuccess();
    }
  }

  async setup() {
    try {
      const paymentMethods = await this.getPaymentMethods();
      const coreConfiguration = this.coreConfiguration(paymentMethods);
      const dropinConfiguration = this.dropinConfiguration();
      const checkout = await AdyenCheckout(coreConfiguration);
      this._dropin = new Dropin(checkout, dropinConfiguration).mount(
        `#${ADYEN_DROPIN_CONTAINER}`
      );
    } catch (error) {
      console.error("Error setting up Adyen Dropin", error);
    }
  }

  async connectedCallback() {
    const html = (
      <div>
        <div id={FORM_ERROR_CONTAINER}></div>
        <div id={ADYEN_DROPIN_CONTAINER}></div>
      </div>
    );

    this.innerHTML = await renderToString(html);
    this.setup();
  }
}

if (!customElements.get(ADYEN_DROPIN_CONTAINER)) {
  customElements.define(ADYEN_DROPIN_CONTAINER, AdyenDropinContainer);
}
