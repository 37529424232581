import { FormSettings } from "../model/common/FormSettings";
import { FormSettingsType } from "../model/common/FormSettingsType";
import AdyenProviderSettings from "../model/common/providers/adyen/AdyenProviderSettings";
import {
  AdyenDropinContainer,
  AdyenDropinContainerConfiguration,
} from "./adyen-dropin-container";
import { PaymentsSummary } from "./payments-summary";
import { renderToString } from "jsx-async-runtime";
import {
  API_BASE_ADDRESS,
  BRAND_HEADER,
  CULTURE_HEADER,
  IS_TEST_ENVIRONMENT,
  LIVE,
  PG_BRAND,
  PG_CULTURE,
  SESSION_ID,
  TEST,
  ADYEN_DROPIN_CONTAINER,
  HOST_CONTAINER,
  PAYMENTS_SUMMARY,
  PAYMENTS_WEB_COMPONENT,
  OMIT,
  INCLUDE,
  SPINNER,
  FORM_LOADING_SPINNER,
  BUNDLE_LOADING_SPINNER,
} from "../constants";
import HttpRequest from "../utils/HttpRequest";
import { PaymentProviderType } from "../model/common/PaymentProviderType";
import { Spinner, SpinnerProps } from "./spinner";

class PaymentsWebComponent extends HTMLElement {
  apiBaseUrl: string;
  sessionId: string;
  isTestEnvironment: boolean;
  brand: string;
  culture: string;
  hostContainerId: string;
  constructor() {
    super();
  }

  async getFormSettings(): Promise<FormSettings> {
    const headers = new Headers();
    headers.append(BRAND_HEADER, this.brand);
    headers.append(CULTURE_HEADER, this.culture);

    let credentials = OMIT as RequestCredentials;
    if (this.isTestEnvironment) {
      credentials = INCLUDE as RequestCredentials;
    }
    const url = `${this.apiBaseUrl}/formsettings/${this.sessionId}`;
    const response = await HttpRequest.get({
      url,
      credentials,
      brand: this.brand,
      culture: this.culture,
    });
    return response.data;
  }

  async connectedCallback() {
    this.sessionId = this.getAttribute(SESSION_ID);
    this.brand = this.getAttribute(PG_BRAND);
    this.culture = this.getAttribute(PG_CULTURE);
    this.apiBaseUrl = this.getAttribute(API_BASE_ADDRESS);
    this.isTestEnvironment = this.getAttribute(IS_TEST_ENVIRONMENT) === "true";
    this.hostContainerId = HOST_CONTAINER + "-" + this.sessionId;

    const html = <div id={this.hostContainerId}></div>;
    this.innerHTML = await renderToString(html);

    let environment: typeof LIVE | typeof TEST = TEST;
    if (!this.isTestEnvironment) {
      environment = LIVE;
    }

    const spinnerProps = this.getAttribute("spinner-props");
    if (!!spinnerProps) {
      const spinner = document.createElement(SPINNER) as Spinner;
      document.getElementById(this.hostContainerId).hidden = true;
      spinner.id = FORM_LOADING_SPINNER;
      spinner.spinnerProps = { label: "Loading", size: "large", ...JSON.parse(spinnerProps) as SpinnerProps };
      document.getElementById(this.hostContainerId).insertAdjacentElement("afterend", spinner);
    }

    const formSettings = await this.getFormSettings();
    const paymentMethod = formSettings.paymentMethods.find(
      (x) => x.providerSettings.providerType === PaymentProviderType.Adyen
    );
    const clientKey = (paymentMethod.providerSettings as AdyenProviderSettings)
      .clientKey;

    const adyenDropinConfiguration: AdyenDropinContainerConfiguration = {
      clientKey: clientKey,
      environment: environment,
      sessionId: this.sessionId,
      brand: this.brand,
      culture: this.culture,
      apiBaseUrl: this.apiBaseUrl,
      hostContainerId: this.hostContainerId,
      ...formSettings,
    };

    let hostContainer = document.getElementById(this.hostContainerId);
    hostContainer.replaceChildren(); // Clear down the host container

    const adyenDropinContainer = document.createElement(
      ADYEN_DROPIN_CONTAINER
    ) as AdyenDropinContainer;
    adyenDropinContainer.configuration = adyenDropinConfiguration;
    hostContainer.appendChild(adyenDropinContainer);

    if (formSettings.type === FormSettingsType.Payment) {
      const paymentSummary = document.createElement(
        PAYMENTS_SUMMARY
      ) as PaymentsSummary;
      paymentSummary.formSettings = formSettings;
      hostContainer
        .insertBefore(
          paymentSummary,
          document.querySelector(ADYEN_DROPIN_CONTAINER)
        );
    }

    let spinner = document.getElementById(BUNDLE_LOADING_SPINNER);
    if (!!spinner) {
      spinner.hidden = true;
    }
  }
}

if (!customElements.get(PAYMENTS_WEB_COMPONENT)) {
  customElements.define(PAYMENTS_WEB_COMPONENT, PaymentsWebComponent);
}
