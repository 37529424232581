import { renderToString } from "jsx-async-runtime";
import Money from "../model/money";
import PaymentSettings from "../model/payment/PaymentSettings";
import { PAYMENTS_SUMMARY } from "../constants";

export class PaymentsSummary extends HTMLElement {
  private _formSettings: PaymentSettings;

  constructor() {
    super();
  }

  get formSettings(): PaymentSettings {
    return this._formSettings;
  }

  set formSettings(value: PaymentSettings) {
    this._formSettings = value;
  }

  formatMoney(value: Money) {
    const formSettingsData = this.formSettings;

    if (!value) return "";

    if (value.value === 0)
      return formSettingsData.resources.commonPayment.freeProductText;

    let cultureCode = formSettingsData.cultureCode;

    if (cultureCode === "ar-SA") cultureCode = "ar-u-nu-latn";

    return Intl.NumberFormat(cultureCode, {
      style: "currency",
      currency: value.currency,
      currencyDisplay: "symbol",
    }).format(value.value);
  }

  async connectedCallback() {
    const formSettingsData = this.formSettings;

    const {
      gymName,
      membershipType,
      purchaseDescription,
      displayStartDate,
      startDate,
      endDate,
      multiGymCount,
    } = formSettingsData.paymentSummary;
    const { overviewLines, finePrintLinesV2 } =
      formSettingsData.paymentTimelineSummary;
    const hasAdditionalInfo =
      !!multiGymCount || (displayStartDate && !!startDate) || !!endDate;
    const hasFinePrintLines = finePrintLinesV2.length > 0;

    let additionalClass =
      formSettingsData.paymentSummary.membershipType === "Plus" ? "plus" : "";

    if (!hasAdditionalInfo) {
      additionalClass = additionalClass.concat(" no-additional-info");
    }

    const html = (
      <div class={`payment-summary ${additionalClass}`}>
        {!!gymName && !!purchaseDescription ? (
          <div
            data-testid="summaryHeaderSection"
            class="payment-summary-header-section"
          >
            <div
              data-testid="membershipHeader"
              class={`membership-header ${additionalClass}`}
            >
              <div data-testid="productDescription" class="product-description">
                <div class="wrapper">
                  <p class={`text ${additionalClass}`}>{purchaseDescription}</p>
                </div>
              </div>
              <div data-testid="gymName" class="gym-name">
                <div class="wrapper">
                  <p class="text">{gymName}</p>
                </div>
              </div>
            </div>
            {hasAdditionalInfo ? (
              <div
                data-testid="additionalInfo"
                class={`additional-info ${additionalClass}`}
              >
                {multiGymCount ? (
                  <div
                    data-testid="multiGymCount"
                    class="additional-info-entry"
                  >
                    <div class="wrapper">
                      <p class="text">
                        {formSettingsData.resources.paymentSummary.numberOfGyms}
                      </p>
                    </div>
                    <p class="text">{multiGymCount}</p>
                  </div>
                ) : (
                  ""
                )}
                {displayStartDate && startDate ? (
                  <div data-testid="startDate" class="additional-info-entry">
                    <div class="wrapper">
                      <p class="text">
                        {formSettingsData.resources.paymentSummary.startDate}
                      </p>
                    </div>
                    <p class="text">{startDate}</p>
                  </div>
                ) : (
                  ""
                )}
                {endDate ? (
                  <div data-testid="endDate" class="additional-info-entry">
                    <div class="wrapper">
                      <p class="text">
                        {formSettingsData.resources.paymentSummary.endDate}
                      </p>
                    </div>
                    <p class="text">{endDate}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div class={`summary-wrapper ${additionalClass}`}>
          <div
            data-testid="paymentTimelineSummary"
            class="payment-timeline-summary"
          >
            {overviewLines.map((overviewLine, idx) => {
              let additionalClass =
                document.documentElement.dir === "rtl" ? "rtl" : "";
              // Used to name the data-testId depending if it's the first or second element
              let initialOrNext = "initial";
              if (idx === 0) {
                if (membershipType === "Plus")
                  additionalClass = additionalClass.concat(" plus");
                else additionalClass = additionalClass.concat(" core");
              } else {
                additionalClass = additionalClass.concat(" next-payments");
                initialOrNext = "next";
              }
              return (
                <div
                  dir={document.documentElement.dir}
                  class={`timeline-entry ${additionalClass}`}
                >
                  <div class="dot-connector-wrapper">
                    <span
                      data-testid="timelineDot-1"
                      class={`dot ${additionalClass}`}
                    ></span>
                    <span
                      data-testid="timelineConnector"
                      class={
                        overviewLines.length !== idx + 1 ? "connector" : ""
                      }
                    ></span>
                  </div>
                  <div
                    data-testid={`${initialOrNext}PaymentSummaryCard`}
                    class={`card ${additionalClass}`}
                  >
                    <div class="wrapper">
                      <div
                        data-testid="initialPaymentSummaryCardOverviewLine"
                        class="overview-line"
                      >
                        <div class="wrapper">
                          <div class="overview-summary-description-wrapper">
                            <p
                              data-testid={`${initialOrNext}PaymentSummaryOverviewDescriptionSummary`}
                              class="overview-summary-description"
                            >
                              {overviewLine.description}
                            </p>
                          </div>
                          <p
                            data-testid={`${initialOrNext}PaymentSummaryOverviewNormalAmountSummary`}
                            class="overview-normal-amount"
                          >
                            {this.formatMoney(overviewLine.normalAmount)}
                          </p>
                          <p
                            data-testid={`${initialOrNext}PaymentSummaryOverviewAmountSummary`}
                            class="overview-amount"
                          >
                            {this.formatMoney(overviewLine.amount)}
                          </p>
                        </div>
                      </div>
                      {overviewLine.orderLines.map((orderLine, idx) => {
                        return (
                          <div
                            data-testid={`${idx + 1}-initialPaymentOrderLine`}
                            class="order-line"
                          >
                            <div class="wrapper">
                              <div class="order-line-description-wrapper">
                                <p
                                  data-testid={`${idx + 1}-initialPaymentOrderLineDescription`}
                                  class="order-line-description"
                                >
                                  {orderLine.description}
                                </p>
                              </div>
                              <p
                                data-testid={`${idx + 1}-initialPaymentOrderLineNormalAmount`}
                                class="order-line-normal-amount"
                              >
                                {this.formatMoney(orderLine.normalAmount)}
                              </p>
                              <p
                                data-testid={`${idx + 1}-initialPaymentOrderLineAmount`}
                                class="order-line-amount"
                              >
                                {this.formatMoney(orderLine.amount)}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {hasFinePrintLines && (
            <div data-testid="finePrintSection" class="fine-print-section">
              <ul variant="standard" class="fine-print-list">
                {finePrintLinesV2
                  .filter((l) => l.type === "Subscription")
                  .map((fineprintLine, idx) => {
                    return (
                      <li
                        data-testid={`finePrintLine${idx}`}
                        class="fine-print-entry"
                      >
                        {fineprintLine.info}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      </div>
    );

    this.innerHTML = await renderToString(html);
  }
}

if (!customElements.get(PAYMENTS_SUMMARY)) {
  customElements.define(PAYMENTS_SUMMARY, PaymentsSummary);
}
